export const colors = {
  aqua: '#30e7d8',
  pink: '#d41367',
  mustard: '#daa901',
  redError: '#e7304d',
  orangeWarn: '#f90',
  greyModalBackground: '#333',
  greyBorder: '#dedede',
  greyBorderLight: '#efefef',
  greyText: '#666',
  greyTextLight: '#888',
  text: '#000',
  inputText: '#000',
  switchGrey: '#dedede',
  portraitIcon: '#e5bcab',
  landscapeIcon: '#abe5e0',
  templateIcon: '#e5bcab',
  squareIcon: '#7096b0',
  optionIcon: '#efefef',
  tentfoldIcon: '#abd4e5',
  canvasBackground: '#f9f9f9',
  canvasRuleLight: '#f0efef',
  canvasRuleDark: '#e7e7e7',
  menuItemBackground: '#f9f9f9',
  superLightGrey: '#f6f6f6',
}

export const gutter = {
  tiny: '4px',
  small: '8px',
  medium: '16px',
  large: '24px',
  xlarge: '32px',
}

export const card = {
  cutMarginMM: 3,
}

const fontFamily = 'Roboto, Arial, Tahoma, sans-serif'

export const fontSizes = {
  tiny: '10px',
  small: '12px',
  medium: '14px',
  large: '16px',
  xlarge: '18px',
}

export const fonts = {
  tiny: `normal ${fontSizes.tiny}/1 ${fontFamily}`,
  small: `normal ${fontSizes.small}/1.2 ${fontFamily}`,
  medium: `normal ${fontSizes.medium}/1.4 ${fontFamily}`,
  large: `normal ${fontSizes.large}/1.3 ${fontFamily}`,
  xlarge: `normal ${fontSizes.xlarge}/1.3 ${fontFamily}`,
}

export const easing = {
  bounce: 'cubic-bezier(0.44, 0.14, 0.53, 1.4)',
  toolbox: 'cubic-bezier(0.43, 0.07, 0.13, 1.21)',
}

const theme = {
  colors: {
    white: '#fff',
    default: 'transparent',
    outlined: 'transparent',
    primary: colors.pink,
    secondary: colors.mustard,
    dark: '#2a2a2a',
    grey: '#f6f6f6',
    text: {
      default: 'black',
      outlined: 'black',
      primary: 'white',
      secondary: 'white',
      dark: 'white',
      grey: 'black',
      main: '#000',
    },
  },
  button: {
    borderRadii: {
      medium: '20px',
      large: '5px',
      small: '15px',
    },
    hoverBackground: {
      default: '#00000011',
      outlined: '#efefef',
      primary: '#d7203d',
      secondary: '#20d7c8',
      dark: '#4c4c4c',
      grey: '#d5d5d5',
    },
    heights: {
      small: '30px',
      medium: '40px',
      large: '50px',
    },
    font: {
      small: fonts.small,
      medium: fonts.medium,
      large: fonts.large,
    },
    variants: {
      default: {
        border: '1px solid transparent',
      },
      outlined: {
        border: '1px solid #dedede',
      },
      primary: {
        border: 'none',
      },
      secondary: {
        border: 'none',
      },
      dark: {
        border: 'none',
      },
      grey: {
        border: 'none',
      },
    },
  },
  dialog: {
    borderRadius: '5px',
  },
  textfield: {
    border: `1px solid ${colors.greyBorder}`,
    borderFocused: `1px solid ${colors.aqua}`,
    borderError: `1px solid ${colors.redError}`,
  },
  switch: {
    variants: {
      default: {
        border: colors.switchGrey,
        markBackground: colors.switchGrey,
        tickFill: 'black',
      },
      outlined: {
        border: colors.switchGrey,
        markBackground: colors.switchGrey,
        tickFill: 'black',
      },
      primary: {
        border: 'transparent',
        markBackground: 'white',
        tickFill: colors.pink,
      },
      secondary: {
        border: 'transparent',
        markBackground: 'white',
        tickFill: colors.aqua,
      },
      dark: {
        border: 'transparent',
        markBackground: 'white',
        tickFill: '#2a2a2a',
      },
      grey: {
        border: 'transparent',
        markBackground: 'white',
        tickFill: '#2a2a2a',
      },
    },
  },
  toast: {
    colors: {
      success: colors.aqua,
      warn: '#2a2a2a',
      error: colors.redError,
    },
  },
}

export default theme
