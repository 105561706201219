import {
  CardDefinition,
  Element,
  ElementImage,
  ElementText,
  Side,
  Size,
  UserImage,
} from '../types/api.model'
import uuid from 'uuid-random'
import { cardBleed, defaultFont } from '../utils/card'

const cardTemplate: CardDefinition = {
  // Keep this for later
  // constraints: {
  //   front: {
  //     image: 1,
  //     text: 0,
  //   },
  //   back: {
  //     image: 1,
  //     text: 2,
  //   },
  // },
  template: {
    front: {
      elements: [
        // Keep this for later
        // (size: Size) => {
        //   const aspect = 3200 / 2134
        //   const cardAspect = size.width / size.height
        //   const heightFirst = aspect > cardAspect
        //   const w = heightFirst ? size.height * aspect : size.width
        //   const h = heightFirst ? size.height : size.width / aspect
        //   return {
        //     type: 'image',
        //     w,
        //     h,
        //     x: heightFirst ? -(w - size.width) / 2 : 0,
        //     y: heightFirst ? 0 : -(h - size.height) / 2,
        //     z: 1,
        //     url:
        //       'https://s3.fr-par.scw.cloud/crearty-app-local/images/a6c76dcf-2024-4b9c-8ec9-468553ebbae4/a6c76dcf-2024-4b9c-8ec9-468553ebbae4_RGB.jpg',
        //     uuid: 'a6c76dcf-2024-4b9c-8ec9-468553ebbae4',
        //     app_uuid: 'init_image',
        //     originalWidth: 3200,
        //     originalHeight: 2134,
        //   }
        // },
      ],
    },
    inside_left: {
      elements: [],
    },
    back: {
      elements: [
        (size: Size) => ({
          type: 'text',
          x: 5,
          y: size.height - 10,
          z: 1,
          w: size.width - 10,
          h: 10,
          textAlign: 'center',
          color: '#000000',
          fontFamily: defaultFont,
          fontSize: 6,
          text: 'Made at crearty.io',
          app_uuid: 'init_text',
        }),
      ],
    },
    inside_right: {
      elements: [],
    },
  },
}

const newText = (size: Size, elements: Side['elements']): ElementText => {
  const initProps = {
    color: '#000000',
    fontFamily: defaultFont,
    fontSize: 12,
    h: 15,
  }

  // inherit the settings from the latest ElementText on the current
  // side of the design
  const [latestTextEl] = elements.filter(el => el.type === 'text').reverse()

  if (latestTextEl) {
    initProps.color = (latestTextEl as ElementText).color
    initProps.fontFamily = (latestTextEl as ElementText).fontFamily
    initProps.fontSize = (latestTextEl as ElementText).fontSize
    initProps.h = (latestTextEl as ElementText).h
  }

  return {
    type: 'text',
    app_uuid: uuid(),
    text: '',
    textAlign: 'left',
    w: size.width * 0.66,
    x: size.width * 0.165,
    y: size.height / 2 - 10,
    z: elements.length + 1,
    ...initProps,
  }
}

const newImage = (
  size: Size,
  border: number,
  elements: Side['elements'],
  image: UserImage
): ElementImage => {
  const cardAspect = size.width / size.height
  const lockedAspect = image.width / image.height
  const bleed = cardBleed
  const heightFirst = lockedAspect > cardAspect

  let w = heightFirst ? size.height * lockedAspect : size.width
  let h = heightFirst ? size.height : size.width / lockedAspect
  w += bleed * 2
  h += bleed * 2
  w -= border * 2
  h -= border * 2

  // check if there's text on this side of the card and
  // insert the new image below it

  const zsorter = (a: Element, b: Element) =>
    a.z > b.z ? 1 : a.z < b.z ? -1 : 0

  const texts = elements.filter(el => el.type === 'text').sort(zsorter)

  const images = elements.filter(el => el.type === 'image').sort(zsorter)

  const highestImage = images.length > 0 ? images[0].z : -1
  const nextInsertZ = texts.length > 0 ? texts[0].z - 1 : elements.length + 1

  return {
    url: image.image,
    type: 'image',
    app_uuid: uuid(),
    uuid: image.uuid,
    originalWidth: image.width,
    originalHeight: image.height,
    h,
    w,
    x: heightFirst ? -(w - size.width - bleed * 2) / 2 : border,
    y: heightFirst ? border : -(h - size.height - bleed * 2) / 2,
    z: Math.max(highestImage, nextInsertZ),
    rotate: 0,
  }
}

export { cardTemplate, newText, newImage }
