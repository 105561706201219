export const tooltipOrder = [
  'image-drag-drop',
  'add-text-image-canvas',
  'add-element-sidebar',
  'flip-sides',
  'rename-design',
  'zoom-in-out',
  'change-card-options',
  'change-card-options-left',
  'selectable-hint',
] as const
