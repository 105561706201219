import { CardDefinition, Size } from '../types/api.model'

const processTemplate = (size: Size, definition: CardDefinition) => {
  const {
    front: { elements: frontElements },
    back: { elements: backElements },
  } = definition.template

  const newFrontElements = frontElements.map(el => {
    if (typeof el === 'function') {
      return el(size)
    }
    return el
  })
  const newBackElements = backElements.map(el => {
    if (typeof el === 'function') {
      return el(size)
    }
    return el
  })

  return {
    ...definition,
    template: {
      front: { elements: newFrontElements },
      back: { elements: newBackElements },
    },
  }
}

export { processTemplate }
