import React, { FC, lazy, memo, useEffect, Suspense } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { actions as optionsActions } from './state/options'
import { actions as templateActions } from './state/templates'
import { Route, Routes } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

Sentry.init({
  dsn: 'https://3e9ea79e992d4b5591e50c5115234a6d@o866064.ingest.sentry.io/5826962',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

const LazyEditor = lazy(() => import('./components/Editor'))

const AppEl = styled.div`
  background-color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const App: FC = memo(() => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(optionsActions.getOptions())
    dispatch(templateActions.getTemplateCategories())
    // dispatch(templateActions.getTemplates())
  }, [])

  return (
    <AppEl>
      <Routes>
        <Route
          path="new"
          element={
            <Suspense fallback={<></>}>
              <LazyEditor startNew />
            </Suspense>
          }
        />
        <Route
          index
          element={
            <Suspense fallback={<></>}>
              <LazyEditor />
            </Suspense>
          }
        />
        <Route
          path=":uuid"
          element={
            <Suspense fallback={<></>}>
              <LazyEditor />
            </Suspense>
          }
        />
        <Route path="login-required" element={'Login required'} />
      </Routes>
    </AppEl>
  )
})

export default App
