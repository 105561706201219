import { createSelector } from 'reselect'
import { RootState } from '.'
import { ActionType, createAction as cA, getType } from 'typesafe-actions'
import { CreartyWindow } from '../types/global'
import { UserOptions } from '../types/editor.model'
// Types

const appConfig = (window as CreartyWindow).CREARTY_APP_CONFIG ?? {
  is_authenticated: undefined,
  csrf_token: null,
  urls: null,
  live_chat_url: null,
}

export type UserState = {
  authed: boolean
  token: string | null
  urls: { [key: string]: string }
  live_chat_url: string
  options: UserOptions
}

const initialState: UserState = {
  authed: appConfig.is_authenticated,
  token: appConfig.csrf_token,
  urls: appConfig.urls,
  live_chat_url: appConfig.live_chat_url,
  options: {
    snapToGrid: true,
    gridSize: 2,
  },
}

// Selectors
export const getUser = (state: RootState) => state.user
export const getIsAuthed = createSelector(getUser, state => state.authed)
export const getToken = createSelector(getUser, state => state.token)
export const getUrls = createSelector(getUser, state => state.urls)
export const getLiveChatUrl = createSelector(
  getUser,
  state => state.live_chat_url
)
export const getOptions = createSelector(getUser, state => state.options)
// Actions

export const actions = {
  init: cA('user/INIT')<{ authed: boolean; token: string }>(),
  updateOption: cA('user/UPDATE_OPTION')<Partial<UserOptions>>(),
}

// Reducer

export const userReducer = (
  state: UserState = initialState,
  action: ActionType<typeof actions>
): UserState => {
  switch (action.type) {
    case getType(actions.init):
      const { authed, token } = action.payload ?? {}
      return {
        ...state,
        authed,
        token,
      }

    case getType(actions.updateOption):
      return { ...state, options: { ...state.options, ...action.payload } }

    default:
      return state
  }
}
