import { combineReducers } from 'redux'
import { all, call, spawn } from 'redux-saga/effects'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { documentReducer, documentSaga } from './document'
import { apiReducer, apiSaga } from './api'
import { optionsReducer, optionsSaga } from './options'
import { userReducer } from './user'
import { messagesReducer, messagesSaga } from './messages'
import { imagesReducer, imagesSaga } from './images'
import { tooltipSaga, tooltipsReducer } from './tooltips'
import { templatesSaga, templatesReducer } from './templates'

// Reducer

const persistPrefix = 'creartyv1:'
const persistConfig = {
  key: `${persistPrefix}root`,
  storage,
  whitelist: ['document', 'options'],
}

const tooltipPersistConfig = {
  key: `${persistPrefix}tooltips`,
  storage,
  blacklist: ['active'],
}

const userPersistConfig = {
  key: `${persistPrefix}user`,
  storage,
  whitelist: ['options'],
}

const appReducer = combineReducers({
  api: apiReducer,
  document: documentReducer,
  messages: messagesReducer,
  options: optionsReducer,
  user: persistReducer(userPersistConfig, userReducer),
  images: imagesReducer,
  templates: templatesReducer,
  tooltips: persistReducer(tooltipPersistConfig, tooltipsReducer),
})

export type RootState = ReturnType<typeof appReducer>

const persistedReducer = persistReducer(persistConfig, appReducer)

export { persistedReducer as rootReducer }

// Sagas

const handleErrors = (saga: any) =>
  function* () {
    yield spawn(function* () {
      try {
        yield call(saga)
        // eslint-disable-next-line no-console
        console.error(`Unexpected saga termination in ${saga.name}`)
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(`Unexpected saga error in ${saga.name}`, err)
      }
    })
  }

const sagas = [
  apiSaga,
  documentSaga,
  optionsSaga,
  messagesSaga,
  imagesSaga,
  tooltipSaga,
  templatesSaga,
]

export function* rootSaga() {
  yield all(sagas.map(handleErrors).map(saga => call(saga)))
}
