import { AppContextContainer } from './components/AppContext'
import { BrowserRouter as Router } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, store } from './store'
import { Provider } from 'react-redux'
import { Toaster } from './components/Toaster'
import App from './App'
import GlobalStyles from './globalStyles'
import React, { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'

const GlobalStylesAny = GlobalStyles as any

const container = document.getElementById('approot')
const root = createRoot(container!)

root.render(
  <StrictMode>
    <GlobalStylesAny />
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Toaster />
        <AppContextContainer>
          <Router basename="/app">
            <App />
          </Router>
        </AppContextContainer>
      </PersistGate>
    </Provider>
  </StrictMode>
)
