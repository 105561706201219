import React, { FC, ReactNode } from 'react'
import styled from 'styled-components'
import { capabilities, device } from '../../utils/devices'
import theme, { easing, fonts, gutter } from '../../utils/theme'

import { ReactComponent as CloseIcon } from '../../common/svgs/icons-24-close.svg'
import { ReactComponent as SuccessIcon } from '../../common/svgs/icons-24-toast-alert.svg'
import { ReactComponent as WarnIcon } from '../../common/svgs/icons-24-toast-warning.svg'
import { ReactComponent as ErrorIcon } from '../../common/svgs/icons-24-toast-error.svg'

export type ToastProps = {
  variant: 'success' | 'warn' | 'error'
  children: ReactNode | ReactNode[]
  autoDismiss?: boolean
  onClose?: () => void
  stack: number
}

const Root = styled.div<Pick<ToastProps, 'variant' | 'stack'>>`
  align-items: center;
  background-color: ${({ variant }) => theme.toast.colors[variant]};
  border-radius: 5px;
  bottom: ${({ stack }) => stack * 6}px;
  color: ${({ variant }) => (variant === 'success' ? 'black' : 'white')};
  display: flex;
  font: ${fonts.medium};
  justify-content: space-between;
  left: ${gutter.large};
  opacity: ${({ stack }) => (stack > 0 ? 0.8 : 1)};
  padding: ${gutter.medium} ${gutter.small} ${gutter.medium} ${gutter.medium};
  position: absolute;
  right: ${gutter.large};
  transform: scale(${({ stack }) => Math.max(1 - stack * 0.06, 0)});
  z-index: ${({ stack }) => 100 - stack};

  & path {
    fill: ${({ variant }) => (variant === 'success' ? 'black' : 'white')};
  }

  @media ${device.tablet} {
    right: unset;
    left: -50%;
    width: 450px;
  }
`

const Close = styled.div`
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: ${gutter.tiny};

  @media ${capabilities.hover} {
    transition: background-color 200ms ease-out;

    &:hover {
      background-color: #0001;

      svg {
        transform: rotate(180deg);
      }
    }

    & svg {
      transition: transform 200ms ${easing.bounce};
    }
  }
`

const StateIcon = styled.div`
  flex: 0 0 auto;
`

const Message = styled.div`
  flex: 2 1 100%;
  padding: 0 ${gutter.medium};
`

const Toast: FC<ToastProps> = ({ variant, children, onClose, stack }) => {
  const Icon =
    variant === 'error'
      ? ErrorIcon
      : variant === 'warn'
      ? WarnIcon
      : SuccessIcon
  return (
    <Root variant={variant} stack={stack}>
      <StateIcon>
        <Icon />
      </StateIcon>
      <Message>{children}</Message>
      <Close onClick={onClose}>
        <CloseIcon />
      </Close>
    </Root>
  )
}

export { Toast }
