import { stringify } from 'qs'
import { buffers, Channel } from 'redux-saga'
import {
  actionChannel,
  all,
  call,
  fork,
  put,
  select,
  take,
} from 'redux-saga/effects'
import { ActionType, createAction as cA, getType } from 'typesafe-actions'

import { createSelector } from 'reselect'
import { RootState } from './'
import { endpoints } from '../api.endpoints'
import {
  EndpointId,
  ErrorType,
  RootApiError,
  RootRequestBody,
  RootSuccessResponseBody,
} from '../types/api.model'
import { ContentType, HttpMethod, HttpStatusCode } from '../types/http.model'
import { getIsAuthed, getToken } from './user'
import { apiErrorToString } from '../utils/apiErrorToString'
import { actions as messagesActions } from './messages'

const dev = process.env.NODE_ENV === 'development'

// Types

export type ApiState = { [key in EndpointId]?: boolean }

export type PathParams = { [key: string]: string }

export type Query = { [key: string]: string | number | boolean }

export type Headers = { [key: string]: string }

export type CallPayload<T = RootRequestBody> = {
  actions: any
  body?: T
  endpoint: EndpointId
  headers?: { [key: string]: string }
  ignore?: HttpStatusCode[]
  params?: PathParams
  query?: Query
  callback?: (data?: any) => void
}

export type SuccessPayload<T = RootSuccessResponseBody> = {
  call: CallPayload
  code: HttpStatusCode
  data: T
}

export type FailurePayload<T = RootApiError> = {
  error: T
}

// Selectors
export const getApiState = (state: RootState) => state.api

export const getActiveCalls = createSelector(getApiState, state => state)

// Action creators

export const actions = {
  call: cA('api/CALL_API')<CallPayload>(),
  complete: cA('api/COMPLETE_API')<{ endpoint: EndpointId }>(),
}

// Reducer

const initialState: ApiState = {}

export const apiReducer = (
  state: ApiState = initialState,
  action: ActionType<typeof actions>
): ApiState => {
  switch (action.type) {
    case getType(actions.call):
      return {
        ...state,
        [action.payload.endpoint]: true,
      }
    case getType(actions.complete):
      return {
        ...state,
        [action.payload.endpoint]: undefined,
      }
    default:
      return state
  }
}

// Sagas

export function* request(options: CallPayload) {
  const { ignore = [HttpStatusCode.Forbidden], callback } = options
  const endpoint = endpoints[options.endpoint]

  const method = endpoint.method
  const csrfToken: string = yield select(getToken)

  const headers: Headers = {
    Accept: ContentType.Json,
    'Content-Type': endpoint.contentType,
    'X-CSRFToken': csrfToken,
    ...options.headers,
  }

  const isAuthed: boolean = yield select(getIsAuthed)
  if (endpoint.authed && !isAuthed) {
    // if user isn't authed by django and endpoint requires it, throw.
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.error('Not authed')
    }
    return // stop generator from continuing
  }

  if (!endpoint.authed || (endpoint.authed && isAuthed)) {
    const fetchOptions: RequestInit = {
      headers,
      method,
    }
    if (options.body) {
      if (endpoint.contentType === ContentType.Json) {
        Object.assign(headers, { 'Content-Type': ContentType.Json })
        fetchOptions.body = JSON.stringify(options.body)
      } else if (endpoint.contentType === ContentType.MultipartFormData) {
        const headers = fetchOptions.headers as any
        // delete the Content-Type header so fetch can automatically assemble
        // one with the multipart boundary defined
        delete headers['Content-Type']
        fetchOptions.body = options.body as FormData
      }
    }

    let path = options.params
      ? endpoint.path.replace(
          /{([^{}]*)}/g,
          (match: string, name: string) => options.params![name] || match
        )
      : endpoint.path

    path = options.query
      ? `${path}?${stringify(options.query, {
          encode: false,
          skipNulls: true,
        })}`
      : path

    yield put(options.actions.request())

    try {
      const result: Response = yield call(fetch, path, fetchOptions)

      let data:
        | ReturnType<typeof call>
        | ReturnType<typeof result.json>
        | undefined
      const errData: any = {
        detail: dev
          ? 'Error parsing request body as JSON'
          : "Oops that shouldn’t happen, we're on it",
      }
      try {
        data = yield call([result, result.json])
      } catch (err) {
        // nowt
      }

      if (result.ok) {
        const successPayload: SuccessPayload<any> = {
          call: options,
          code: result.status,
          data,
        }
        yield put(actions.complete({ endpoint: options.endpoint }))
        yield put(options.actions.success(successPayload))
      } else {
        const code = result.status

        const dataAny = data as any

        const error: RootApiError = dataAny.errors
          ? {
              code,
              detail: errData.detail,
              errors: Object.keys(dataAny.errors).reduce((prev, curr) => {
                return {
                  ...prev,
                  [curr]: dataAny.errors[curr].join(''),
                }
              }, {}),
              type: ErrorType.Validation,
            }
          : errData.detail
          ? {
              code,
              detail: errData.detail,
              type: ErrorType.Backend,
            }
          : {
              code,
              data: dataAny,
              type: ErrorType.Response,
            }

        const failurePayload: FailurePayload = { error }
        if (!ignore.includes(code)) {
          yield put(
            messagesActions.showMessage({
              message: apiErrorToString(error),
              variant: 'error',
            })
          )
        }
        yield put(actions.complete({ endpoint: options.endpoint }))
        yield put(options.actions.failure(failurePayload))
      }
      if (callback) {
        yield call(callback, data)
      }
    } catch (err) {
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.error(err)
      }
      const failurePayload: FailurePayload = {
        error: {
          code: HttpStatusCode.NoConnection,
          detail: 'Network error',
          type: ErrorType.Client,
        },
      }

      yield put(
        messagesActions.showMessage({
          message: apiErrorToString(failurePayload.error),
          variant: 'error',
        })
      )
      yield put(actions.complete({ endpoint: options.endpoint }))
      yield put(options.actions.failure(failurePayload))

      if (callback) {
        yield call(callback)
      }
    }
  }
}

export function* queueWorker() {
  const channel: string = yield actionChannel(
    [getType(actions.call)],
    buffers.expanding(10)
  )
  while (true) {
    const { payload }: ReturnType<typeof actions.call> = yield take(channel)
    yield call(request, payload)
  }
}

const sagas = [queueWorker]

export function* apiSaga() {
  yield all(sagas.map(saga => fork(saga)))
}
