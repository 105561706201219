import { actions as apiActions } from './api'
import { CallPayload, FailurePayload, SuccessPayload } from './api'
import { cardTemplate, newImage, newText } from '../card-templates/default'
import { createSelector } from 'reselect'
import { getIsAuthed } from './user'
import { HttpStatusCode } from '../types/http.model'
import { processTemplate } from '../card-templates/processor'
import { RootState } from '.'
import { ensureZSortSequential } from '../utils'
import arrayMove from 'array-move'
import {
  ActionType,
  createAsyncAction as cAA,
  createAction as cA,
  getType,
} from 'typesafe-actions'
import {
  Element,
  DesignReq,
  DesignRes,
  EndpointId,
  Sides,
  Size,
  Border,
  Material,
  UserImage,
} from '../types/api.model'
import {
  all,
  delay,
  fork,
  put,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects'
import {
  actions as optionsActions,
  getBorders,
  getDefaultBorder,
  getDefaultMaterial,
  getOptions,
  getSizes,
} from './options'

const DEFAULT_DOC_VERSION = '1.2'
const MAX_UNDOS = 30

// Types
export type DocumentState = {
  autoSelect: string | null
  editing: boolean
  future: Array<string>
  latest?: 'local' | 'remote'
  past: Array<string>
  present: DesignRes
  saving: boolean
  updateCount: number
}

const initialState: DocumentState = {
  autoSelect: null,
  past: [],
  present: {
    data_version: DEFAULT_DOC_VERSION,
    data: {
      sides: {
        front: { elements: [] },
        inside_left: { elements: [] },
        back: { elements: [] },
        inside_right: { elements: [] },
      },
      border: '',
      size: '',
      material: '',
    },
    name: 'Untitled Card',
    updated_date: '',
    uuid: null,
    isNew: true,
  },
  future: [],
  editing: false,
  latest: 'local',
  saving: false,
  updateCount: 0,
}

// Selectors
export const getDocument = (state: RootState) => state.document

export const getPresentDocument = createSelector(
  getDocument,
  state => state.present
)

export const getCanUndo = createSelector(
  getDocument,
  state => state.past.length > 0
)

export const getCanRedo = createSelector(
  getDocument,
  state => state.future.length > 0
)

export const getCardName = createSelector(
  getDocument,
  state => state.present.name
)

export const getIsNew = createSelector(
  getDocument,
  state => state.present.isNew
)

export const getLatestLocation = createSelector(
  getDocument,
  state => state.latest || `local`
)

export const getUpdateCount = createSelector(
  getDocument,
  state => state.updateCount
)

export const getAutoSelect = createSelector(
  getDocument,
  state => state.autoSelect
)

export const getIsFromTemplate = createSelector(
  getDocument,
  state => state.present.from_template === true
)

export const getSaving = createSelector(getDocument, state => state.saving)

export const getEditing = createSelector(getDocument, state => state.editing)

export const getIsFreshDocument = createSelector(getDocument, state => {
  const {
    data: { size },
  } = state.present
  return size === ''
})

// Actions

export const actions = {
  load: cA('design/LOAD')<{ uuid: string }>(),
  loadRequest: cAA(
    'design/LOAD_REQUEST',
    'design/LOAD_SUCCESS',
    'design/LOAD_FAILURE'
  )<void, SuccessPayload<DesignRes>, FailurePayload>(),
  save: cA('design/SAVE')<DesignReq, boolean>(),
  saveAndRegeneratePreview: cA('design/SAVE_REGENERATE')(),
  saveRequest: cAA(
    'design/SAVE_REQUEST',
    'design/SAVE_SUCCESS',
    'design/SAVE_FAILURE',
    'design/SAVE_CANCEL'
  )<void, SuccessPayload<DesignRes>, FailurePayload, undefined>(),
  saveRegenerateRequest: cAA(
    'design/SAVE_REGEN_REQUEST',
    'design/SAVE_REGEN_SUCCESS',
    'design/SAVE_REGEN_FAILURE',
    'design/SAVE_REGEN_CANCEL'
  )<void, SuccessPayload<DesignRes>, FailurePayload, undefined>(),
  new: cA('design/NEW_DOCUMENT')(),
  newWithTemplate: cA('design/NEW_WITH_TEMPLATE')<{ template: DesignRes }>(),
  editing: cA('design/EDITING')<boolean>(),
  purge: cA('design/PURGE')(),
  redo: cA('design/REDO')(),
  undo: cA('design/UNDO')(),
  update: cA('design/UPDATE')<{ document: DesignRes }>(),
  updateSide: cA('design/UPDATE_SIDE')<{
    side: Sides
    elements: Element[]
    replace?: boolean // allows overwriting of present document without changing history
  }>(),
  updateOption: cA('design/UPDATE_OPTION')<{
    option: 'material' | 'size' | 'border'
    uuid: string
    isNew?: boolean
  }>(),
  newSetSize: cA('design/NEW_SET_SIZE')<{
    uuid: string
  }>(),
  newElement: cA('design/NEW_ELEMENT')<{
    side: Sides
    element: Element
  }>(),
  newElementText: cA('design/NEW_ELEMENT_TEXT')<{
    side: Sides
  }>(),
  newElementImage: cA('design/NEW_ELEMENT_IMAGE')<{
    side: Sides
    image: UserImage
  }>(),
  deleteElement: cA('design/DELETE_ELEMENT')<{
    side: Sides
    element: Element
  }>(),
  updateElement: cA('design/UPDATE_ELEMENT')<{
    side: Sides
    element: Element
  }>(),
  updateCount: cA('design/UPDATE_COUNT')(),
  updateName: cA('design/UPDATE_NAME')<{ name: string }>(),
  zSort: cA('design/CHANGE_ELEMENT_Z')<{
    side: Sides
    element: Element
    dir: 'up' | 'down'
  }>(),
  clearAutoSelect: cA('design/CLEAR_AUTOSELECT')(),
  upgradeDocumentVersion: cA('design/UPGRADE_VERSION')(),
}

// Reducer

export const documentReducer = (
  state: DocumentState = initialState,
  action: ActionType<typeof actions>
): DocumentState => {
  let past, present, future

  switch (action.type) {
    case getType(actions.purge):
      return {
        ...initialState,
      }
    case getType(actions.new):
      return {
        ...initialState,
      }
    case getType(actions.newWithTemplate):
      // copy the loaded design and clear uuid and set template
      // related data
      const template = {
        ...action.payload.template,
        uuid: null,
        template_base: action.payload.template.uuid ?? undefined,
        from_template: true,
        state: 'open',
      }

      return {
        ...initialState,
        present: template,
      }

    case getType(actions.update):
      const { document } = action.payload
      past = [JSON.stringify(state.present), ...state.past.slice(0, MAX_UNDOS)]

      return {
        ...state,
        past,
        present: document,
        future: [],
        editing: state.editing,
        latest: 'local',
      }

    case getType(actions.updateSide): {
      const { side, elements } = action.payload

      past = [JSON.stringify(state.present), ...state.past.slice(0, MAX_UNDOS)]
      return {
        ...state,
        past,
        present: {
          ...state.present,
          data: {
            ...state.present.data,
            sides: {
              ...state.present.data.sides,
              [side as Sides]: { elements },
            },
          },
        },
        future: [],
        editing: state.editing,
        latest: 'local',
      }
    }

    case getType(actions.editing):
      return { ...state, editing: action.payload }

    case getType(actions.updateElement): {
      const { side, element } = action.payload
      past = [JSON.stringify(state.present), ...state.past.slice(0, MAX_UNDOS)]
      present = { ...state.present }

      const currElements: Element[] = JSON.parse(
        JSON.stringify(present.data.sides[side].elements)
      )

      const index = currElements.findIndex(
        el => el.app_uuid === element.app_uuid
      )
      // quit while we're ahead
      if (index === -1) return { ...state }

      const newElements = [
        ...currElements.slice(0, index),
        JSON.parse(JSON.stringify(element)),
        ...currElements.slice(index + 1),
      ]

      present.data.sides[side].elements = newElements

      return {
        ...state,
        past,
        present,
        future: [],
        editing: state.editing,
        latest: 'local',
      }
    }

    case getType(actions.deleteElement): {
      const { side: deleteSide, element } = action.payload
      past = [JSON.stringify(state.present), ...state.past.slice(0, MAX_UNDOS)]
      present = { ...state.present }

      const currElements: Element[] = JSON.parse(
        JSON.stringify(present.data.sides[deleteSide].elements)
      )

      const index = currElements.findIndex(
        el => el.app_uuid === element.app_uuid
      )
      // quit while we're ahead
      if (index === -1) return { ...state }

      currElements.splice(index, 1)

      present.data.sides[deleteSide].elements = currElements

      return {
        ...state,
        past,
        present,
        future: [],
        editing: state.editing,
        latest: 'local',
      }
    }

    case getType(actions.newElement): {
      const { side, element } = action.payload
      past = [JSON.stringify(state.present), ...state.past.slice(0, MAX_UNDOS)]
      present = { ...state.present }

      // ensure z indexes are sequential
      const newElements = ensureZSortSequential([
        ...present.data.sides[side].elements,
        element,
      ])

      present.data.sides[side].elements = newElements

      return {
        ...state,
        autoSelect: element.app_uuid,
        past,
        present,
        future: [],
        editing: state.editing,
        latest: 'local',
      }
    }

    case getType(actions.newSetSize):
      present = { ...state.present }
      present.data.size = action.payload.uuid
      present.isNew = false
      return {
        ...state,
        past: [],
        present,
        future: [],
        editing: state.editing,
        latest: 'local',
      }

    case getType(actions.updateOption):
      const { option, uuid } = action.payload
      past = [JSON.stringify(state.present), ...state.past.slice(0, MAX_UNDOS)]
      present = { ...state.present }
      present.data[option] = uuid
      return {
        ...state,
        past,
        present,
        future: [],
        editing: state.editing,
        latest: 'local',
      }

    case getType(actions.updateName):
      const { name } = action.payload
      past = [JSON.stringify(state.present), ...state.past.slice(0, MAX_UNDOS)]
      present = { ...state.present }
      present.name = name
      return {
        ...state,
        past,
        present,
        future: [],
        editing: state.editing,
        latest: 'local',
      }

    case getType(actions.undo):
      if (state.past.length > 0) {
        past = [...state.past]
        present = JSON.parse(past.shift() || '') as DesignRes
        future = [JSON.stringify(state.present), ...state.future]
        return {
          ...state,
          past,
          present,
          future,
          editing: false,
          latest: 'local',
        }
      } else {
        return state
      }

    case getType(actions.redo):
      if (state.future.length > 0) {
        future = [...state.future]
        present = JSON.parse(future.shift() || '') as DesignRes
        past = [
          JSON.stringify(state.present),
          ...state.past.slice(0, MAX_UNDOS),
        ]
        return {
          ...state,
          past,
          present,
          future,
          editing: false,
          latest: 'local',
        }
      } else {
        return state
      }

    case getType(actions.saveRequest.request):
    case getType(actions.saveRegenerateRequest.request):
      return { ...state, saving: true }

    case getType(actions.saveRequest.success):
    case getType(actions.saveRegenerateRequest.success):
      // inject loaded uuid in to state without pulluting other local document state
      if (state.present.uuid === null && action.payload.data.uuid !== null) {
        present = {
          ...state.present,
          ...action.payload.data,
          uuid: action.payload.data.uuid,
        }
        return { ...state, present, latest: 'remote', saving: false }
      } else {
        return { ...state, latest: 'remote', saving: false }
      }

    case getType(actions.loadRequest.success):
      // if (action.payload.data.uuid === state.present.uuid) {
      //   return { ...state }
      // }

      return {
        ...state,
        past: [],
        present: {
          ...action.payload.data,
          isNew: false,
        },
        future: [],
        editing: state.editing,
        latest: 'remote',
      }

    case getType(actions.zSort): {
      const { dir, side, element } = action.payload

      past = [JSON.stringify(state.present), ...state.past.slice(0, MAX_UNDOS)]
      present = { ...state.present }

      const currElements: Element[] = JSON.parse(
        JSON.stringify(present.data.sides[side].elements)
      )

      const index = currElements.findIndex(
        el => el.app_uuid === element.app_uuid
      )
      // quit while we're ahead
      if (index === -1) return { ...state }

      const newElements = arrayMove(
        currElements,
        index,
        dir === 'up' ? index + 1 : index - 1
      )

      newElements.forEach((el, index) => {
        el.z = index + 1
      })

      present.data.sides[side].elements = newElements

      return {
        ...state,
        past,
        present,
        future: [],
        editing: state.editing,
        latest: 'local',
      }
    }

    case getType(actions.updateCount):
      return { ...state, updateCount: (state.updateCount || 0) + 1 }

    case getType(actions.clearAutoSelect):
      return { ...state, autoSelect: null }

    case getType(actions.upgradeDocumentVersion):
      // if insides aren't present, let's brute force them in to
      // the loaded document
      const { data } = state.present

      if (state.present.data_version === DEFAULT_DOC_VERSION) {
        return state
      }

      // strip HTML tags from old docs
      Object.entries(data.sides).forEach(([key, { elements }]) => {
        elements.forEach((element: Element) => {
          if (element.type === 'text') {
            const tmp = window.document.createElement("DIV");
            tmp.innerHTML = element.text ?? '';
            element.text = tmp.innerText
          }
        })
      })

      if (data.sides.inside_left === undefined) {
        data.sides.inside_left = { elements: [] }
        data.sides.inside_right = { elements: [] }
      }

      return {
        ...state,
        present: { ...state.present, data_version: DEFAULT_DOC_VERSION, data },
      }

    default:
      return state
  }
}

// Sagas
export function* newElementTextWorker() {
  while (true) {
    const {
      payload: { side },
    }: ReturnType<typeof actions.newElementText> = yield take(
      getType(actions.newElementText)
    )

    // get size details
    const {
      data: { size: sizeUuid, sides },
    }: DocumentState['present'] = yield select(getPresentDocument)

    yield put(optionsActions.getOptions())
    yield take([getType(optionsActions.getOptionsRequest.success)])
    const sizes: any[] = yield select(getSizes)
    const size = sizes.find((size: Size) => size.uuid == sizeUuid)

    // create new element
    const element = newText(size, sides[side].elements)
    yield put(actions.newElement({ side, element }))
  }
}

export function* newElementImageWorker() {
  while (true) {
    const {
      payload: { side, image },
    }: ReturnType<typeof actions.newElementImage> = yield take(
      getType(actions.newElementImage)
    )

    // get size details
    const {
      data: { size: sizeUuid, border: borderUuid, sides },
    }: DocumentState['present'] = yield select(getPresentDocument)

    yield put(optionsActions.getOptions())
    yield take([getType(optionsActions.getOptionsRequest.success)])
    const sizes: any[] = yield select(getSizes)
    const size = sizes.find((size: Size) => size.uuid == sizeUuid)

    const borders: any[] = yield select(getBorders)
    const border: Border = borders.find(
      (border: Border) => border.uuid === borderUuid
    )

    // create new element
    const element = newImage(
      size,
      border?.width || 0,
      sides[side].elements,
      image
    )
    yield put(actions.newElement({ side, element }))
  }
}

export function* applyTemplateWorker() {
  while (true) {
    const {
      payload: { uuid },
    }: ReturnType<typeof actions.newSetSize> = yield take(
      getType(actions.newSetSize)
    )

    // get size details
    const {
      data: { size: sizeUuid },
    }: DocumentState['present'] = yield select(getPresentDocument)

    yield put(optionsActions.getOptions())
    yield take([getType(optionsActions.getOptionsRequest.success)])
    const sizes: any[] = yield select(getSizes)
    const size = sizes.find((size: Size) => size.uuid == sizeUuid)

    // generate template
    const templateDef = processTemplate(size, cardTemplate)
    yield put(
      actions.updateSide({
        side: 'front',
        elements: templateDef.template.front.elements,
        replace: true,
      })
    )
    yield put(
      actions.updateSide({
        side: 'back',
        elements: templateDef.template.back.elements,
        replace: true,
      })
    )
  }
}

export function* setDefaultsWatcher() {
  while (true) {
    yield take([
      getType(actions.new),
      getType(optionsActions.getOptionsRequest.success),
    ])
    const doc: DesignRes = yield select(getPresentDocument)

    if (doc.isNew) {
      yield put(optionsActions.getOptions())
      yield take([getType(optionsActions.getOptionsRequest.success)])
      const border: Border = yield select(getDefaultBorder)
      const material: Material = yield select(getDefaultMaterial)

      if (!doc.data.border) doc.data.border = border?.uuid
      if (!doc.data.material) doc.data.material = material?.uuid
    }
  }
}

export function* autoSaveWatcher() {
  // all the calls that should trigger a card save
  yield takeLatest(
    [
      getType(actions.deleteElement),
      getType(actions.newElement),
      getType(actions.newSetSize),
      getType(actions.update),
      getType(actions.updateElement),
      getType(actions.updateName),
      getType(actions.updateOption),
      getType(actions.zSort),
    ],
    autoSaveWorker
  )
}

export function* saveAndRegenerateWorker() {
  while (true) {
    yield take(getType(actions.saveAndRegeneratePreview))

    const isAuthed = select(getIsAuthed)
    const doc: DesignRes = yield select(getPresentDocument)

    if (isAuthed) {
      yield put(actions.save(doc, true))
      // yield put(actions.updateCount())
    }
  }
}

// if there's been no changes for n seconds since the last change, save
export function* autoSaveWorker() {
  const isAuthed = select(getIsAuthed)
  yield put(actions.saveRequest.cancel())
  yield delay(2000)
  const doc: DesignReq = yield select(getPresentDocument)

  // get number of sides
  const { sizes } = yield select(getOptions)
  const currentSize = sizes.find((size: Size) => size.uuid === doc.data.size)

  const sides = currentSize.sides
  if (sides === 2) {
    doc.data.sides.inside_left = undefined
    doc.data.sides.inside_right = undefined
  } else if (
    sides === 4 &&
    doc.data.sides.inside_left === undefined &&
    doc.data.sides.inside_right === undefined
  ) {
    doc.data.sides.inside_left = { elements: [] }
    doc.data.sides.inside_right = { elements: [] }
  }

  const editing: boolean = yield select(getEditing)
  // yield put(actions.updateCount())
  if (!editing && isAuthed) yield put(actions.save(doc, false))
}

export function* saveWorker() {
  while (true) {
    const { payload, meta }: ReturnType<typeof actions.save> = yield take(
      getType(actions.save)
    )

    const { uuid } = payload

    // auto upgrade
    yield put(actions.upgradeDocumentVersion())
    const options: CallPayload = {
      actions: meta ? actions.saveRegenerateRequest : actions.saveRequest,
      endpoint: uuid
        ? meta
          ? EndpointId.UpdateDesignAndRegeneratePreview
          : EndpointId.UpdateDesign
        : EndpointId.CreateDesign,
      params: uuid ? { uuid } : undefined,
      body: payload,
    }
    yield put(apiActions.call(options))
    const doc: DesignRes = yield select(getPresentDocument)
  }
}

export function* loadWorker() {
  while (true) {
    const {
      payload: { uuid },
    }: ReturnType<typeof actions.load> = yield take(getType(actions.load))

    const latestLocation: DocumentState['latest'] = yield select(
      getLatestLocation
    )
    yield put(actions.upgradeDocumentVersion())

    const doc: DesignRes = yield select(getPresentDocument)

    if ((uuid && latestLocation === 'remote') || uuid !== doc.uuid) {
      const options: CallPayload = {
        actions: actions.loadRequest,
        endpoint: EndpointId.LoadDesign,
        params: { uuid },
        ignore: [HttpStatusCode.NotFound],
      }
      yield put(apiActions.call(options))
    }
  }
}

// if the load fails, redirect out
export function* loadFailed() {
  while (true) {
    yield take(getType(actions.loadRequest.failure))
    const doc: DocumentState = yield select(getDocument)

    if (doc.latest === 'remote') {
      const isAuthed: boolean = yield select(getIsAuthed)
      if (isAuthed) {
        window.location.href = '/account/designs'
      } else {
        window.location.href = '/app/new'
      }
    }
  }
}

export function* saveSuccessWorker() {
  while (true) {
    yield take(getType(actions.saveRequest.success))
    const doc: DesignRes = yield select(getPresentDocument)
    if (window.location.href.indexOf('/app/new') !== -1) {
      history.replaceState(null, '', `/app/${doc.uuid}`)
    }
  }
}

export function* saveRegenerateSuccessWorker() {
  while (true) {
    yield take(getType(actions.saveRegenerateRequest.success))
    yield put(actions.updateCount())
  }
}

// if the newly selected size doesn't support the currently selected material
// default it to the first available one
export function* updateMaterialTypeOnSizeChangeWorker() {
  while (true) {
    const {
      payload: { option },
    }: ReturnType<typeof actions.updateOption> = yield take(
      getType(actions.updateOption)
    )
    if (option === 'size') {
      const doc: DesignRes = yield select(getPresentDocument)
      const sizes: Array<Size> = yield select(getSizes)
      const currSize = sizes.find(size => size.uuid === doc.data.size)

      if (
        !currSize?.materials?.includes(doc.data.material) &&
        currSize?.materials?.[0] !== undefined
      ) {
        yield put(
          actions.updateOption({
            option: 'material',
            uuid: currSize?.materials?.[0],
          })
        )
      }
    }
  }
}

const sagas = [
  updateMaterialTypeOnSizeChangeWorker,
  newElementTextWorker,
  newElementImageWorker,
  applyTemplateWorker,
  setDefaultsWatcher,
  saveWorker,
  saveSuccessWorker,
  saveRegenerateSuccessWorker,
  loadWorker,
  loadFailed,
  autoSaveWatcher,
  saveAndRegenerateWorker,
]

export function* documentSaga() {
  yield all(sagas.map(saga => fork(saga)))
}
