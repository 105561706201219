import { ContentType, HttpMethod, HttpStatusCode } from './http.model'

export type Mutable<T> = {
  -readonly [K in keyof T]: T[K]
}

// prettier-ignore
export enum EndpointId {
  GetProductOptions,
  GetTemplates,
  GetTemplateCatergories,
  LoadDesignAsTemplate,
  CreateDesign,
  UpdateDesign,
  UpdateDesignAndRegeneratePreview,
  LoadDesign,
  GetImages,
  UploadImage,
}

export type Endpoint = {
  authed: boolean
  contentType: ContentType
  method: HttpMethod
  path: string
}

export type Endpoints = { [key in EndpointId]: Endpoint }

// Bodies for requests and responses

export type RootRequestBody = EmptyReq

export type RootSuccessResponseBody = ProductOptions

export type BackendErrorBody = {
  detail: string
}

export type ValidationErrorBody<T = RootRequestBody> = {
  detail: string
  errors: { [K in keyof T]?: string[] } & { non_field_errors?: string[] }
}

export type RootErrorBody = BackendErrorBody | ValidationErrorBody

export type RootResponseBody = RootSuccessResponseBody | RootErrorBody

// Errors

export enum ErrorType {
  Validation = 'ValidationError', // The api thinks thinks the data in the request body was invalid
  Backend = 'BackendError', // The request was ok, but something went wrong on the backend
  Client = 'ClientError', // The request failed because of a problem on the client
  Response = 'ResponseError', // The request succeeded, but the response represents some sort of business error state
}

export type ValidationError<T = ValidationErrorBody> = {
  type: ErrorType.Validation
  detail: string
  code: HttpStatusCode
  errors: { [K in keyof T]?: string } & { non_field_errors?: string }
}

export type BackendError = {
  type: ErrorType.Backend
  detail: string
  code: HttpStatusCode
}

export type ClientError = {
  type: ErrorType.Client
  detail: string
  code: HttpStatusCode
}

export type ResError<T = any> = {
  type: ErrorType.Response
  code: HttpStatusCode
  data: T
  detail?: string
}

export type RootApiError =
  | ValidationError
  | BackendError
  | ClientError
  | ResError

// Transit models
export type EmptyReq = {}

export type Size = {
  shape: 'portrait' | 'landscape' | 'square'
  name: string
  width: number
  height: number
  image: string
  uuid: string
  sides?: 2 | 4
  folds?: 0 | 1 | 2
  fold_type?: 'gate' | 'tent'
  materials?: Array<string>
}

export type Material = {
  is_default: boolean
  is_in_stock: boolean
  name: string
  weight: string
  image: string
  uuid: string
}

export type Border = {
  is_default: boolean
  name: string
  width: number
  image: string
  uuid: string
}

export type Price = {
  price: number
  size: string
  material: string
}

export type ProductOptions = {
  borders: Array<Border>
  materials: Array<Material>
  sizes: Array<Size>
  prices: Array<Price>
}

export type TemplateCategory = {
  name: string
}

export type Template = {
  template_category: string
  name: string
  thumbnail: string
  uuid: string
}

export type TemplateOptions = {
  templates: Array<Template>
  categories: Array<TemplateCategory>
}

// Data
export type DesignDocumentStub = {
  border: string
  size: string
  material: string
  uuid?: string
  image_spread?: string
  image_front?: string
  image_spread_thumb?: string
  image_front_thumb?: string
}

export type DesignDocument = DesignDocumentStub & {
  sides: {
    front: Side
    inside_left: Side
    back: Side
    inside_right: Side
  }
}

export type DesignReqDocument = DesignDocumentStub & {
  sides: {
    front: Side
    inside_left?: Side
    back: Side
    inside_right?: Side
  }
}

export type Sides = 'front' | 'back' | 'inside_left' | 'inside_right'

export type Side = {
  elements: Element[]
}

type ElementBase = {
  x: number
  y: number
  z: number
  w: number
  h: number
  app_uuid: string
}

export type ElementImage = ElementBase & {
  type: 'image'
  url: string
  uuid?: string
  originalHeight: number
  originalWidth: number
  rotate: number
}

export type ElementText = ElementBase & {
  type: 'text'
  fontSize: number
  fontFamily: string
  text?: string
  textAlign?: 'left' | 'center' | 'right'
  color: string
}

export type Element = ElementText | ElementImage

export type DesignRes = {
  created_date?: string
  data_version: string
  data: DesignDocument
  from_template?: boolean
  image_front_thumb?: string
  image_front?: string
  image_spread_thumb?: string
  image_spread?: string
  isNew: boolean
  name: string
  state?: string
  template_base?: string
  updated_date: string
  uuid?: string | null
}

export type DesignState = DesignRes

export type DesignReq = {
  data_version: string
  data: DesignReqDocument
  name: string
  updated_date: string
  uuid?: string | null
}

// Card templates/constraints
export type SideConstraints = {
  image: number
  text: number
}

type GenerateElement = (size: Size) => Element
export type PredefSide = {
  elements: Array<Element | GenerateElement>
}

export type CardDefinition = {
  // constraints: {
  //   front: SideConstraints
  //   inside_left: SideConstraints
  //   back: SideConstraints
  //   inside_right: SideConstraints
  // }
  template: {
    front: PredefSide
    inside_left: PredefSide
    back: PredefSide
    inside_right: PredefSide
  }
}

// Images
export type UserImage = {
  image: string
  thumbnail: string
  width: number
  height: number
  uuid: string
}

export type UserImages = {
  count: number
  next: string | null
  previous: string | null
  results: UserImage[]
}
