import { createGlobalStyle } from 'styled-components'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GlobalStyles = createGlobalStyle`
  body {
    font-family: 'Roboto', Arial, Tahoma, sans-serif;
  }

  * {
    box-sizing: border-box;
  }
`

export default GlobalStyles
