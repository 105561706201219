import { createSelector } from 'reselect'
import { RootState } from '.'
import { ActionType, createAction as cA, getType } from 'typesafe-actions'
import { tooltipOrder } from '../tooltips'
import { all, fork, put, select, take } from '@redux-saga/core/effects'
import { call } from 'redux-saga/effects'

// Types
export type ToolTipState = {
  enabled: boolean
  viewed: string[]
  active: string[]
}

const initialState: ToolTipState = {
  enabled: true,
  viewed: [...tooltipOrder],
  active: [],
}

// Selectors
export const getTooltips = (state: RootState) => state.tooltips

export const getViewedTooltips = createSelector(
  getTooltips,
  state => state.viewed
)

export const getActiveTooltips = createSelector(
  getTooltips,
  state => state.active
)

export const getTooltipsEnabled = createSelector(
  getTooltips,
  state => state.enabled
)

export const getPreferredTooltip = createSelector(
  getTooltips,
  ({ active, viewed }) => {
    let index: number | undefined = undefined

    active.forEach(item => {
      const foundAt = viewed.indexOf(item)
      if (foundAt !== -1) {
        if (index === undefined || foundAt < index) {
          index = foundAt
        }
      }
    })
    return viewed[index ?? 0]
  }
)
// Actions

export const actions = {
  registerTooltip: cA('tooltip/REGISTER_TOOLTIP')<{
    id: string
    callback: (isDuplicate: boolean) => void
  }>(),
  postRegisterTooltip: cA('tooltop/POST_REGISTER_TOOLTIP')<{ id: string }>(),
  unregisterTooltip: cA('tooltip/UNREGISTER_TOOLTIP')<{ id: string }>(),
  disableTooltips: cA('tooltip/DISABLE_TOOLTIPS')(),
  enableTooltips: cA('tooltip/ENABLE_TOOLTIPS')(),
  markTooltipRead: cA('tooltip/MARK_TOOLTIP_READ')<{
    id: string
  }>(),
  resetTooltips: cA('tooltip/RESET')(),
}

// Reducer
export const tooltipsReducer = (
  state: ToolTipState = initialState,
  action: ActionType<typeof actions>
): ToolTipState => {
  let index: number
  let list: string[]

  switch (action.type) {
    case getType(actions.disableTooltips):
      return { ...state, enabled: false }

    case getType(actions.enableTooltips):
      return { ...state, enabled: true }

    case getType(actions.registerTooltip):
      return { ...state }

    case getType(actions.postRegisterTooltip):
      return {
        ...state,
        active: [...new Set([...(state.active || []), action.payload.id])],
      }

    case getType(actions.unregisterTooltip):
      index = state.active.indexOf(action.payload.id)
      if (index !== -1) {
        list = [...state.active]
        list.splice(index, 1)
        return { ...state, active: list }
      }
      return { ...state }

    case getType(actions.markTooltipRead):
      index = state.viewed.indexOf(action.payload.id)
      if (index !== -1) {
        list = [...state.viewed]
        list.splice(index, 1)
        return { ...state, viewed: list }
      }
      return { ...state }

    case getType(actions.resetTooltips):
      return { ...state, enabled: true, viewed: [...initialState.viewed] }

    default:
      return state
  }
}

export function* registerTooltipWorker() {
  while (true) {
    const {
      payload: { id, callback },
    }: ReturnType<typeof actions.registerTooltip> = yield take(
      getType(actions.registerTooltip)
    )
    const activeTooltips: ToolTipState['active'] = yield select(
      getActiveTooltips
    )
    const isDuplicate = activeTooltips.indexOf(id) !== -1

    if (!isDuplicate) {
      yield put(actions.postRegisterTooltip({ id }))
    }
    yield call(callback, isDuplicate)
  }
}

const sagas = [registerTooltipWorker]

export function* tooltipSaga() {
  yield all(sagas.map(saga => fork(saga)))
}
