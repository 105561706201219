export const cardBleed = 3

export const availableFonts = [
  { family: 'Abril Fatface', size: 24 },
  { family: 'Aleo', size: 14 },
  { family: 'Amatic', size: 14 },
  { family: 'Bebas Neue', size: 14 },
  { family: 'Great Vibes', size: 14 },
  { family: 'Helvetica', size: 12 },
  { family: 'Kalam', size: 14 },
  { family: 'Nunito Sans', size: 14 },
  { family: 'Open Sans Bold', size: 14 },
  { family: 'Open Sans', size: 14 },
  { family: 'Sue Ellen Francisco', size: 14 },
  { family: 'Ubuntu', size: 14 },
  { family: 'Vollkorn Bold', size: 14 },
  { family: 'Vollkorn', size: 14 },
]

export const availableFontColours = [
  '#000000',
  '#FFFFFF',
  '#DB0032',
  '#DB6015',
  '#FFDA00',
  '#789904',
  '#DF3FAF',
  '#00A0DF',
  '#773DBD',
]

export const defaultFont = 'Nunito Sans'
export const defaultFontColour = '#000000'
