import { Size, UserImage } from '../types/api.model'
import { Element } from '../types/api.model'

export function debounce(this: any, fn: Function, timeout = 300) {
  let timer: ReturnType<typeof setTimeout> | undefined
  return (...args: Array<any>) => {
    if (timer !== undefined) clearTimeout(timer)
    timer = setTimeout(() => {
      fn.apply(this, args)
    }, timeout)
  }
}

export function clamp(num: number, a: number, b: number) {
  return Math.max(Math.min(num, Math.max(a, b)), Math.min(a, b))
}

export function imageDPI(image: UserImage, size: Size) {
  const dpiW = (image.width * 25.4) / size.width
  const dpiH = (image.height * 25.4) / size.height

  return Math.min(dpiW, dpiH)
}

export function ensureZSortSequential(elements: Element[]) {
  const sorted = elements.sort((a, b) => (a.z > b.z ? 1 : a.z < b.z ? -1 : 0))
  sorted.forEach((el, index) => {
    if (index > 0) {
      while (el.z <= sorted[index - 1].z) {
        el.z = el.z + 1
      }
    }
  })

  return sorted
}
