import React, {
  FC,
  createContext,
  ReactNode,
  useState,
  Dispatch,
  SetStateAction,
} from 'react'

type ContextProps = {
  editorActions: ReactNode | ReactNode[] | null
  setEditorActions: Dispatch<SetStateAction<null>>
  userActions: ReactNode | ReactNode[] | null
  setUserActions: Dispatch<SetStateAction<null>>
  settingsModal: boolean
  setSettingsModal: Dispatch<SetStateAction<boolean>>
}

const contextDefaults: ContextProps = {
  editorActions: null,
  setEditorActions: () => {},
  userActions: null,
  setUserActions: () => {},
  settingsModal: false,
  setSettingsModal: () => {},
}

const AppContext = createContext(contextDefaults)

export type AppContextProviderProps = {
  children: ReactNode | ReactNode[]
}
const AppContextContainer: FC<AppContextProviderProps> = ({ children }) => {
  const [editorActions, setEditorActions] = useState(null)
  const [userActions, setUserActions] = useState(null)
  const [settingsModal, setSettingsModal] = useState(false)

  return (
    <AppContext.Provider
      value={{
        editorActions,
        setEditorActions,
        userActions,
        setUserActions,
        settingsModal,
        setSettingsModal,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export { AppContext, AppContextContainer }
