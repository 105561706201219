import { createSelector } from 'reselect'
import { RootState } from '.'
import { EndpointId, UserImage, UserImages } from '../types/api.model'
import {
  ActionType,
  createAsyncAction as cAA,
  createAction as cA,
  getType,
} from 'typesafe-actions'
import {
  actions as apiActions,
  CallPayload,
  FailurePayload,
  SuccessPayload,
} from './api'
import { all, fork, put, select, take } from 'redux-saga/effects'
import { getIsAuthed } from './user'
import { ContentType } from '../types/http.model'

// Types
export type ImagesState = UserImages

const initialState: ImagesState = {
  count: 0,
  next: null,
  previous: null,
  results: [],
}

// Selectors
export const getImages = (state: RootState) => state.images
export const getImageList = createSelector(getImages, state => state.results)

// Actions
export const actions = {
  getImages: cA('images/GET_IMAGES')<{ callback?: (data: any) => void }>(),
  getImagesRequest: cAA(
    'options/GET_IMAGES_REQUEST',
    'options/GET_IMAGES_SUCCESS',
    'options/GET_IMAGES_FAILURE'
  )<void, SuccessPayload<UserImages>, FailurePayload>(),
  uploadImage: cA('images/UPLOAD')<{
    callback: (data: any) => void
    file: File
  }>(),
  uploadImageRequest: cAA(
    'options/UPLOAD_REQUEST',
    'options/UPLOAD_SUCCESS',
    'options/UPLOAD_FAILURE'
  )<void, SuccessPayload<UserImage>, FailurePayload>(),
}

// Reducer

export const imagesReducer = (
  state: ImagesState = initialState,
  action: ActionType<typeof actions>
): ImagesState => {
  switch (action.type) {
    case getType(actions.getImagesRequest.success):
      const { data } = action.payload
      return {
        ...data,
      }
    default:
      return state
  }
}

// Sagas
export function* getImagesWorker() {
  while (true) {
    const action: ReturnType<typeof actions.getImages> = yield take(
      getType(actions.getImages)
    )

    const authed: Boolean = yield select(getIsAuthed)

    if (authed) {
      const images: CallPayload = {
        actions: actions.getImagesRequest,
        endpoint: EndpointId.GetImages,
        query: { limit: '1000' },
        callback: action.payload.callback ?? undefined,
      }
      yield put(apiActions.call(images))
    }
  }
}

export function* uploadWorker() {
  while (true) {
    const action: ReturnType<typeof actions.uploadImage> = yield take(
      getType(actions.uploadImage)
    )

    const { callback, file } = action.payload

    if (file) {
      const fd = new FormData()
      fd.append('image', file)

      const options: CallPayload<FormData> = {
        actions: actions.uploadImageRequest,
        body: fd,
        endpoint: EndpointId.UploadImage,
        headers: {
          'Content-Disposition': `attachment; filename=${file.name}`,
        },
        callback,
      }
      yield put(apiActions.call(options))
    }
  }
}

const sagas = [getImagesWorker, uploadWorker]

export function* imagesSaga() {
  yield all(sagas.map(saga => fork(saga)))
}
