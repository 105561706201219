import {
  ErrorType,
  RootApiError,
  ResError,
  ValidationError,
} from '../types/api.model'
import { HttpStatusCode } from '../types/http.model'

const apiErrorToString = (error: RootApiError) => {
  const dev = process.env.NODE_ENV === 'development'
  const prefix = dev
    ? `${error.type} HTTP ${error.code} ${HttpStatusCode[error.code]}:`
    : ''
  let message

  switch (true) {
    case error.type === ErrorType.Response && dev:
      message = `${prefix} ${JSON.stringify((error as ResError).data, null, 2)}`
      break
    case error.type === ErrorType.Validation && dev:
      message = `${prefix} ${error.detail} ${JSON.stringify(
        (error as ValidationError).errors,
        null,
        2
      )}`
      break
    default:
      message = `${prefix}${error.detail}`
      break
  }

  return message
}

export { apiErrorToString }
