import { createSelector } from 'reselect'
import { RootState } from '.'
import {
  ActionType,
  createAsyncAction as cAA,
  createAction as cA,
  getType,
} from 'typesafe-actions'
import { all, fork, put, take } from 'redux-saga/effects'
import {
  actions as apiActions,
  CallPayload,
  FailurePayload,
  SuccessPayload,
} from './api'
import { EndpointId, ProductOptions } from '../types/api.model'
import { CreartyWindow } from '../types/global'

// Types
export type OptionsState = ProductOptions

const appConfig = (window as CreartyWindow).CREARTY_APP_CONFIG

const initialState: OptionsState = {
  sizes: [],
  materials: [],
  borders: [],
  prices: [],
}

// Selectors
export const getOptions = (state: RootState) => state.options
export const getMaterials = createSelector(getOptions, state => state.materials)
export const getSizes = createSelector(getOptions, state => state.sizes)
export const getBorders = createSelector(getOptions, state => state.borders)
export const getDefaultBorder = createSelector(getOptions, state => {
  let defaultBorder = state.borders.find(border => border.is_default)
  if (appConfig.border_seed) {
    defaultBorder = state.borders.find(
      border => border.uuid === appConfig.border_seed
    )
  }
  if (!defaultBorder && state.borders[0]) return state.borders[0]
  return defaultBorder
})
export const getDefaultMaterial = createSelector(getOptions, state => {
  let defaultMaterial = state.materials.find(material => material.is_default)
  if (appConfig.material_seed) {
    defaultMaterial = state.materials.find(
      material => material.uuid === appConfig.material_seed
    )
  }
  if (!defaultMaterial && state.materials[0]) return state.materials[0]
  return defaultMaterial
})

// Actions

export const actions = {
  getOptions: cA('options/GET_OPTIONS')(),
  getOptionsRequest: cAA(
    'options/GET_OPTIONS_REQUEST',
    'options/GET_OPTIONS_SUCCESS',
    'options/GET_OPTIONS_FAILURE'
  )<void, SuccessPayload<ProductOptions>, FailurePayload>(),
}

// Reducer

export const optionsReducer = (
  state: OptionsState = initialState,
  action: ActionType<typeof actions>
): OptionsState => {
  switch (action.type) {
    case getType(actions.getOptionsRequest.success):
      const { data } = action.payload
      return {
        ...data,
        borders: data.borders.sort((a, b) => (a.width < b.width ? -1 : 1)),
      }
    default:
      return state
  }
}

// Sagas
export function* getOptionsWorker() {
  while (true) {
    yield take(getType(actions.getOptions))

    const options: CallPayload = {
      actions: actions.getOptionsRequest,
      endpoint: EndpointId.GetProductOptions,
    }
    yield put(apiActions.call(options))
  }
}

const sagas = [getOptionsWorker]

export function* optionsSaga() {
  yield all(sagas.map(saga => fork(saga)))
}
