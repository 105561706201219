import { createSelector } from 'reselect'
import { RootState } from '.'
import {
  ActionType,
  createAsyncAction as cAA,
  createAction as cA,
  getType,
} from 'typesafe-actions'
import { all, fork, put, take } from 'redux-saga/effects'
import {
  actions as apiActions,
  CallPayload,
  FailurePayload,
  SuccessPayload,
} from './api'
import {
  DesignRes,
  EndpointId,
  Template,
  TemplateCategory,
  TemplateOptions,
} from '../types/api.model'
import { actions as documentActions } from './document'

// Types
export type TemplatesState = TemplateOptions

const initialState: TemplateOptions = {
  templates: [],
  categories: [],
}

// Selectors
export const getTemplatesState = (state: RootState) => state.templates
export const getCategories = createSelector(
  getTemplatesState,
  state => state.categories
)
export const getTemplates = createSelector(
  getTemplatesState,
  state => state.templates
)
// export const getAllTemplates = createSelector(getOptions, state => state.sizes)
// export const getDefaultBorder = createSelector(getOptions, state => {
//   const defaultBorder = state.borders.find(border => border.is_default)
//   if (!defaultBorder && state.borders[0]) return state.borders[0]
//   return defaultBorder
// })
// export const getDefaultMaterial = createSelector(getOptions, state => {
//   const defaultMaterial = state.materials.find(material => material.is_default)
//   if (!defaultMaterial && state.materials[0]) return state.materials[0]
//   return defaultMaterial
// })

// Actions

export const actions = {
  getTemplateCategories: cA('templates/GET_TEMPLATES')(),
  getTemplateCategoriesRequest: cAA(
    'templates/GET_TEMPLATESCATEGORIES_REQUEST',
    'templates/GET_TEMPLATESCATEGORIES_SUCCESS',
    'templates/GET_TEMPLATESCATEGORIES_FAILURE'
  )<
    void,
    SuccessPayload<{ results: Array<TemplateCategory> }>,
    FailurePayload
  >(),
  getTemplates: cA('templates/GET_TEMPLATES')(),
  getTemplatesRequest: cAA(
    'templates/GET_TEMPLATES_REQUEST',
    'templates/GET_TEMPLATES_SUCCESS',
    'templates/GET_TEMPLATES_FAILURE'
  )<void, SuccessPayload<{ results: Array<Template> }>, FailurePayload>(),
  createFromTemplate: cA('templates/CREATE_FROM_TEMPLATE')<{ uuid: string }>(),
  createFromTemplateRequest: cAA(
    'templates/CREATE_FROM_TEMPLATE_REQUEST',
    'templates/CREATE_FROM_TEMPLATE_SUCCESS',
    'templates/CREATE_FROM_TEMPLATE_FAILURE'
  )<void, SuccessPayload<DesignRes>, FailurePayload>(),
}

// Reducer

export const templatesReducer = (
  state: TemplatesState = initialState,
  action: ActionType<typeof actions>
): TemplatesState => {
  switch (action.type) {
    case getType(actions.getTemplateCategoriesRequest.success):
      return { ...state, categories: action.payload.data.results }
    case getType(actions.getTemplatesRequest.success):
      return { ...state, templates: action.payload.data.results }
    default:
      return state
  }
}

// Sagas
export function* getTemplatesWorker() {
  while (true) {
    yield take(getType(actions.getTemplates))

    const options: CallPayload = {
      actions: actions.getTemplatesRequest,
      endpoint: EndpointId.GetTemplates,
    }
    yield put(apiActions.call(options))
  }
}

export function* getTemplateCategoriesWorker() {
  while (true) {
    yield take(getType(actions.getTemplateCategories))

    const options: CallPayload = {
      actions: actions.getTemplateCategoriesRequest,
      endpoint: EndpointId.GetTemplateCatergories,
    }
    yield put(apiActions.call(options))
  }
}

export function* createFromTemplateRequestWorker() {
  while (true) {
    const {
      payload: { uuid },
    } = yield take(getType(actions.createFromTemplate))

    const options: CallPayload = {
      actions: actions.createFromTemplateRequest,
      endpoint: EndpointId.LoadDesignAsTemplate,
      params: { uuid },
    }
    yield put(apiActions.call(options))
  }
}

export function* createFromTemplateWorker() {
  while (true) {
    const {
      payload: { data },
    }: ReturnType<typeof actions.createFromTemplateRequest.success> =
      yield take(getType(actions.createFromTemplateRequest.success))

    yield put(documentActions.newWithTemplate({ template: data }))

    // if (data.uuid) {
    //   window.location.href = `/app/new/`
    // }
  }
}

const sagas = [
  getTemplatesWorker,
  getTemplateCategoriesWorker,
  createFromTemplateRequestWorker,
  createFromTemplateWorker,
]

export function* templatesSaga() {
  yield all(sagas.map(saga => fork(saga)))
}
