import { EndpointId, Endpoints } from './types/api.model'
import { ContentType, HttpMethod } from './types/http.model'

// NOTE: All endpoints must have a trailing slash otherwise
// django redirects in safari will break auth headers.
export const endpoints: Endpoints = {
  [EndpointId.GetProductOptions]: {
    authed: false,
    contentType: ContentType.Json,
    method: HttpMethod.Get,
    path: '/api/products/options/',
  },
  [EndpointId.CreateDesign]: {
    authed: true,
    contentType: ContentType.Json,
    method: HttpMethod.Post,
    path: '/api/designs/',
  },
  [EndpointId.UpdateDesign]: {
    authed: true,
    contentType: ContentType.Json,
    method: HttpMethod.Put,
    path: '/api/designs/{uuid}/',
  },
  [EndpointId.UpdateDesignAndRegeneratePreview]: {
    authed: true,
    contentType: ContentType.Json,
    method: HttpMethod.Put,
    path: '/api/designs/{uuid}/regenerate_preview/',
  },
  [EndpointId.LoadDesign]: {
    authed: true,
    contentType: ContentType.Json,
    method: HttpMethod.Get,
    path: '/api/designs/{uuid}/',
  },
  [EndpointId.GetImages]: {
    authed: true,
    contentType: ContentType.Json,
    method: HttpMethod.Get,
    path: '/api/images/',
  },
  [EndpointId.UploadImage]: {
    authed: false,
    contentType: ContentType.MultipartFormData,
    method: HttpMethod.Post,
    path: '/api/images/',
  },
  [EndpointId.GetTemplates]: {
    authed: false,
    contentType: ContentType.Json,
    method: HttpMethod.Get,
    path: '/api/designs/templates/',
  },
  [EndpointId.GetTemplateCatergories]: {
    authed: false,
    contentType: ContentType.Json,
    method: HttpMethod.Get,
    path: '/api/designs/template-categories/',
  },
  [EndpointId.LoadDesignAsTemplate]: {
    authed: false,
    contentType: ContentType.Json,
    method: HttpMethod.Get,
    path: '/api/designs/{uuid}/',
  },
}
