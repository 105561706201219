import { all, delay, fork, put, take } from 'redux-saga/effects'
import { createSelector } from 'reselect'
import { ActionType, createAction as cA, getType } from 'typesafe-actions'
import uuid from 'uuid-random'
import { RootState } from './'

// Types
export type Message = {
  autoDismiss?: boolean | number | undefined
  message: string
  open?: boolean
  variant: 'success' | 'warn' | 'error'
  uuid?: string
}

export type MessagesState = {
  messages: Message[]
}
/*
{
  type: 'messages/SHOW_MESSAGE',
  payload: {
    type: 'error',
    message: 'test error'
  }
}
*/
// Selectors

export const getMessagesState = (state: RootState) => state.messages

export const getMessages = createSelector(
  getMessagesState,
  state => state.messages
)

// Action creators

export const actions = {
  addMessage: cA('messages/ADD_MESSAGE')<Message>(),
  hideMessage: cA('messages/HIDE_MESSAGE')<{ uuid: string }>(),
  removeMessage: cA('messages/REMOVE_MESSAGE')<{ uuid: string | undefined }>(),
  showMessage: cA('messages/SHOW_MESSAGE')<Message>(),
}

// Reducer
const initialState: MessagesState = {
  messages: [],
}

export const messagesReducer = (
  state: MessagesState = initialState,
  action: ActionType<typeof actions>
): MessagesState => {
  let index
  switch (action.type) {
    case getType(actions.addMessage):
      const uuids: string[] = []
      const uniqMessages = [action.payload, ...state.messages].filter(
        (message: any) => {
          const duplicate = uuids.includes(message.uuid)
          if (!duplicate) {
            uuids.push(message.uuid)
          }
          return !duplicate
        }
      )
      return {
        ...state,
        messages: uniqMessages,
      }

    case getType(actions.hideMessage):
      index = state.messages.findIndex(
        item => item.uuid === action.payload.uuid
      )
      if (index < 0) {
        return state
      }

      const newMessage = { ...state.messages[index] }
      newMessage.open = false

      return {
        ...state,
        messages: [
          ...state.messages.slice(0, index),
          newMessage,
          ...state.messages.slice(index + 1),
        ],
      }

    case getType(actions.removeMessage):
      index = state.messages.findIndex(
        item => item.uuid === action.payload.uuid
      )
      if (index < 0) {
        return state
      }
      return {
        ...state,
        messages: [
          ...state.messages.slice(0, index),
          ...state.messages.slice(index + 1),
        ],
      }

    default:
      return state
  }
}

// Sagas

export function* createMessageWorker() {
  while (true) {
    const action: ReturnType<typeof actions.showMessage> = yield take(
      getType(actions.showMessage)
    )

    const message = { ...action.payload, uuid: uuid(), open: true }
    yield put(actions.addMessage(message))

    // auto dismiss
    const { autoDismiss } = action.payload
    if (autoDismiss !== undefined) {
      const removeDelay =
        typeof autoDismiss === 'boolean'
          ? autoDismiss === true
            ? 2000
            : 0
          : Number(autoDismiss)
      if (removeDelay > 0) {
        yield delay(removeDelay)
        yield put(actions.hideMessage({ uuid: message.uuid }))
      }
    }
  }
}

const sagas = [createMessageWorker]

export function* messagesSaga() {
  yield all(sagas.map(saga => fork(saga)))
}
