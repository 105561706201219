import React, { FC } from 'react'
import { createPortal } from 'react-dom'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { actions as messagesActions, getMessages } from '../../state/messages'
import { device } from '../../utils/devices'
import { gutter } from '../../utils/theme'
import { Toast } from '../Toast/Toast'

const Root = styled.div`
  bottom: ${gutter.large};
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 2500;

  @media ${device.tablet} {
    left: 50%;
    width: 450px;
  }
`

const Toaster: FC = () => {
  const toasts = useSelector(getMessages)
  const dispatch = useDispatch()

  return createPortal(
    <Root>
      {toasts.slice(0, 5).map((toast, index) => (
        <Toast
          variant={toast.variant}
          key={index}
          onClose={() => {
            dispatch(messagesActions.removeMessage({ uuid: toast.uuid }))
          }}
          stack={index}
        >
          {toast.message}
        </Toast>
      ))}
    </Root>,
    window.document.body
  )
}

export { Toaster }
