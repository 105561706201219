import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from '@redux-devtools/extension'
import { persistStore } from 'redux-persist'
import createSagaMiddleware from 'redux-saga'
import { rootReducer, rootSaga } from './state'

const sagas = createSagaMiddleware()
const middleware: any = [sagas]

const store = createStore(
  rootReducer,
  composeWithDevTools({
    maxAge: 200,
  })(applyMiddleware(...middleware))
)
const persistor = persistStore(store)

sagas.run(rootSaga)

export { store, persistor }
